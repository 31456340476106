import React from 'react';
import '../../assets/assets/scss/DataGrid.css'; // Import CSS for styling


// Custom component to display store logos
const StoreLogo = ({ name, storename }) => {
  // You'll need to replace the paths with actual paths to your logo images
  const logoMap = {
    YouTubeMusic: '../../images/logos/youtubemusic-logo.png',
    Spotify: '../../images/logos/spotify-logo.png',
    AppleMusic: '../../images/logos/applemusic-logo.png',
    YouTubeContentID: '../../images/logos/youtube-logo.png',
    Resso: '../../images/logos/resso-logo.png',
    TikTok: '../../images/logos/tiktok-logo.png',
    iTunes: '../../images/logos/itunes-logo.png',
    AmazonPrimeMusic: '../../images/logos/amazonprimemusic-logo.png',
    IHeartRadio: '../../images/logos/iheartradio-logo.png',
    iTunesMatch: '../../images/logos/itunesmatch-logo.png',
    Pandora: '../../images/logos/pandora-logo.png',
    AmazonMusic: '../../images/logos/amazonmusic-logo.png',
    Tidal: '../../images/logos/tidal-logo.png',
    SoundtrackYourBrand: '../../images/logos/soundtrackyourbrand-logo.png',
    NeteaseCloudMusic: '../../images/logos/neteasecloudmusic-logo.png',
    KKBox: '../../images/logos/kkbox-logo.png',

    YoutubeMCN: '../../images/logos/youtubemcn-logo.png',


  


    // Add more logos for other stores as needed
  };

  return (
    <div className="logo-container">
      <img src={logoMap[name]} alt={name} className="logo" />
      <span className="store-name">{storename}</span>
    </div>
  );
};

const DataGrid = ({ data }) => {
  return (
    <table className="data-grid">
      <thead>
        <tr>
          <th>Store</th>
          <th>Total Views</th>
          <th>Total Earnings</th>
        </tr>
      </thead>
      <tbody>
        {data.map(({ store, totalviews, totalearnings, storename }, index) => (
          <tr key={index} className="data-row">
            <td><StoreLogo name={store} storename={storename} /></td>
            
            <td>{totalviews}</td>
            <td>{totalearnings}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default DataGrid;