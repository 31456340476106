import React, { useState, useContext, useEffect  } from 'react';
import { VectorMap } from 'react-jvectormap';
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, Legend } from 'recharts';
import NavbarMenu from "../../components/NavbarMenu";
import { AuthContext } from '../../shared/context/auth-context';
import ReactEcharts from "echarts-for-react";
import BarChartComponent from '../../components/Charts/BarChartComponent';
import DataGrid from '../../components/Charts/DataGrid';
import DataGridTrack from '../../components/Charts/DataGridTrack';

import StoreSlider from '../../components/Charts/StoreSlider';
import PageHeader from "../../components/PageHeader";

import { Link } from "react-router-dom";
import ReferralsCard from "../../components/Dashboard/ReferralsCard";
import axios from 'axios';
import {
  topProductOption,
  topRevenueOption,
  topRevenueMonthlyOption,
  saleGaugeOption,
  dataManagetOption,
  sparkleCardData,
} from "../../Data/DashbordData";
import { Dropdown } from "react-bootstrap";




const MapComponent = () => {
  const [data, setData] = useState([],)
  const [jsonDataS, setjsontDataS] = useState([]);
  const [jsonData2, setjsonData2] = useState([]);
  const [jsonDataT, setjsontDataT] = useState([]);


  const apiLink = "https://server.lampro.co/api/excelupload/readfile/" + localStorage.getItem('name') ;
  //console.log(apiLink);
  
  

  const apiLinkTotalMonthlyViews = "https://server.lampro.co/api/excelupload/readfilem/" + localStorage.getItem('name') ;

  const apiLinkTotalMonthlyStores = "https://server.lampro.co/api/excelupload/readfilems/" + localStorage.getItem('name') ;
  const apiLinkTotalMonthlyTracks = "https://server.lampro.co/api/excelupload/readfilemt/" + localStorage.getItem('name') ;
  //console.log(apiLinkTotalMonthlyViews);

  useEffect(() => {
    // Fetch data from your Node.js API
    axios.get(apiLink)
      .then(response => {
        const data = response.data;
        setData(data)

      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, []); // Empty dependency array means this effect runs only once after initial render
  
  useEffect(() => {
    // Fetch data from your Node.js API
    axios.post(apiLinkTotalMonthlyViews, {
          "dategreaterthan": "2023-10-02",
          "datelessthan": "2024-12-03",
          "label": localStorage.getItem('name')
    })
      .then(response => {
        const data = response.data;
        setjsonData2(data);

      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, []); // Empty dependency array means this effect runs only once after initial render

  

  

// Function to get month name from month number
  const getMonthName = (monthNumber) => {
  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  return monthNames[monthNumber - 1]; // Months are 0-indexed in JavaScript Date object
};

// Format data into desired structure
const formattedData = jsonData2.map(row => {
  const month = getMonthName(row._id);
  return {
      Month: month,
      totalviews: row.totalviews,
      //totalearnings: row.totalearnings
  };
});
const formattedData2 = jsonData2.map(row => {
  const month = getMonthName(row._id);
  return {
      Month: month,
      //totalviews: row.totalviews,
      totalearnings: row.totalearnings
  };
});
//console.log(jsonData2);
//const totalearningsmonthly = (JSON.stringify(jsonData2.totalearnings))
 //console.log(totalearningsmonthly[0]);
 //console.log(JSON.stringify(jsonData2));
// console.log(JSON.stringify(jsonData2[0]));
 const totalViews = jsonData2.length > 0 ? jsonData2[0].totalviews : 0;
 const totalEarnings = jsonData2.length > 0 ? jsonData2[0].totalearnings : 0;

 const totalEarningsSum = jsonData2.reduce((sum, item) => sum + item.totalearnings, 0).toFixed(2);
 const totalViewsSum= jsonData2.reduce((sum, item) => sum + item.totalviews, 0).toFixed(2);
 //console.log({totalEarningsSum})


 //console.log({totalViews}); 
 //console.log({totalEarnings}); 
//console.log({formattedData});
 
 //console.log(JSON.stringify(jsonData2[0].totalearnings[0]));



 useEffect(() => {
  // Fetch data from your Node.js API
  axios.post(apiLinkTotalMonthlyStores, {
        "dategreaterthan": "2023-10-02",
        "datelessthan": "2024-12-03",
        "label": localStorage.getItem('name')
  })
    .then(response => {
      const data = response.data;
      setjsontDataS(data);

    })
    .catch(error => {
      console.error('Error fetching data:', error);
    });
}, []); // Empty dependency array means this effect runs only once after initial render

//console.log({jsonDataS});

const storeName = jsonDataS.length > 0 ? jsonDataS[0].store.replace(/\s/g, '') : '';
//console.log({storeName});


 const jsonDataStores = [
  { store: 'YouTube', totalviews: 1000, totalearnings: 500 },
  { store: 'Spotify', totalviews: 1500, totalearnings: 700 },
  { store: 'Vimeo', totalviews: 800, totalearnings: 300 },
  // Add more data as needed
];


useEffect(() => {
  // Fetch data from your Node.js API
  axios.post(apiLinkTotalMonthlyTracks, {
        "dategreaterthan": "2023-10-02",
        "datelessthan": "2024-12-03",
        "label": localStorage.getItem('name')
  })
    .then(response => {
      const data = response.data;
      setjsontDataT(data);

    })
    .catch(error => {
      console.error('Error fetching data:', error);
    });
}, []); // Empty dependency array means this effect runs only once after initial render

//console.log({jsonDataT});

const trackName = jsonDataT.length > 0 ? jsonDataT[0].tracktitle.replace(/\s/g, '') : '';
//console.log({trackName});




const jsonDataSlider = [
  { store: 'YouTube', totalviews: 1000, totalearnings: 500, logoUrl: '../../images/logos/youtube-logo.png' },
  { store: 'Spotify', totalviews: 1500, totalearnings: 700, logoUrl: '../../images/logos/spotify-logo.png' },
  { store: 'Vimeo', totalviews: 800, totalearnings: 300, logoUrl: '../../images/logos/vimeo-logo.png' },
  // Add more data as needed
];




    const handleRegionTipShow = (e, el, code) => {
      // Get the value for the hovered country
      const value = data[code];
      // Set tooltip content
      el.html(`<strong>${code}</strong>: ${value}`);
    // Position the tooltip near the mouse pointer
    el.css({
      'top': `${e.pageY + 10}px`,
      'left': `${e.pageX + 10}px`,
      'background': '#fff',
      'border': '1px solid #ccc',
      'padding': '5px',
      'border-radius': '5px',
      'box-shadow': '0 0 10px rgba(0, 0, 0, 0.3)',
      'z-index': '9999',
      'position': 'absolute'
    });
  };


  


  //localStorage.setItem('email', 'aman@aman.com'); 
   const auth = useContext(AuthContext);
    //console.log(localStorage.getItem('email'));
    //console.log(localStorage.getItem('name'));
  

  // Dummy data
  const mapData = {
    // Sample data for demonstration purposes
    US: 300, // Dummy value for United States
    CA: 200, // Dummy value for Canada
    MX: 150, // Dummy value for Mexico
    BR: 250, // Dummy value for Brazil
    AR: 180, // Dummy value for Argentina
    CO: 220, // Dummy value for Colombia
    VE: 170, // Dummy value for Venezuela
    PE: 210, // Dummy value for Peru
    CL: 120, // Dummy value for Chile
    EC: 270, // Dummy value for Ecuador
    GB: 350, // Dummy value for United Kingdom
    DE: 280, // Dummy value for Germany
    FR: 320, // Dummy value for France
    ES: 290, // Dummy value for Spain
    IT: 270, // Dummy value for Italy
    RU: 400, // Dummy value for Russia
    CN: 500, // Dummy value for China
    IN: 450, // Dummy value for India
    JP: 380, // Dummy value for Japan
    AU: 200, // Dummy value for Australia
    ZA: 350, // Dummy value for South Africa
  };

  const linemapData = [
    { name: 'Jan', uv: 4000, pv: 2400, amt: 2400 },
   
  ];

//console.log(mapData);
//console.log(linemapData);

  return (
    
    <div>
    
        {<NavbarMenu />}
        

        <div className="container-fluid" id="main-content">


        <PageHeader
              HeaderText="Dashboard"
              Breadcrumb={[{ name: "Dashboard" }]}
            />
        
      
    



{/* code for bar map and monthly earnings starts  */}


<div className="row clearfix">
            
              <div className="col-lg-9 col-md-9">
                <div className="card">
                  <div className="header">
                  
                    <h2>Streams from Countries</h2>
                    
                  </div>
                  <div className="body">
              
                  <VectorMap
        map={'world_mill'}
        backgroundColor="#ffffff"
        containerStyle={{
          width: '100%',
          height: '350px',
        }}
        regionStyle={{
          initial: {
            fill: '#e4e4e4',
            'fill-opacity': 1,
            stroke: 'none',
            'stroke-width': 0,
            'stroke-opacity': 1,
          },
          hover: {
            'fill-opacity': 0.8,
            cursor: 'pointer',
          },
          selected: {
            fill: '#2938bc', // Color for the clicked country
          },
        }}
        regionsSelectable={true}
        series={{
          regions: [
            {
              values: data, // Dummy data
              scale: ['#FFA500', '#8B0000'], // Color range
              normalizeFunction: 'polynomial',
            },
          ],
        }}
        onRegionTipShow={handleRegionTipShow}
      />
                  </div>
                </div>
              </div>

              <div className="col-lg-3 col-md-3">
                <div className="card">
                  <div className="header">
                  
                    <h2>Total Earnings</h2>
                    <div
                      id="topsaleDonut"
                      style={{ height: 100, width: "100%" }}
                    ></div>
                    <div className="sparkline">
                    <h2>£{totalEarningsSum}</h2>
                    <h6 className="p-b-15">Total Earnings</h6>
                    </div>

                    <div
                      id="topsaleDonut"
                      style={{ height: 100, width: "100%" }}
                    ></div>
                    <div className="sparkline">
                    <h2>{totalViewsSum}</h2>
                    <h6 className="p-b-15">Total Streams</h6>
                    </div>
                    
                  </div>
                  <div className="body">

                  </div>
                  </div>
                  </div>
</div>










<div>
      
      

     



  </div>


</div>
    


</div>



  );
  
};

export default MapComponent;