import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const BarChartComponent = ({ data }) => {
  return (
    <ResponsiveContainer width="100%" height={300}>
      <BarChart data={data} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="Month" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="totalviews" name="Total Streams" fill="#6ebdd1" barSize={40} />
        <Bar dataKey="totalearnings" name="Total Earnings" fill="#afc979" barSize={40} />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default BarChartComponent;
