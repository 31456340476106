import React from "react";
import { connect } from "react-redux";
import { BrowserRouter as Router, Route, Switch, Redirect, withRouter} from "react-router-dom";
import Login from "./screens/Login";
import dashboard from "./screens/Dashbord/Dashbord";
import demographic from "./screens/Dashbord/Demographic";
import ioT from "./screens/Dashbord/IoT";
import NavbarMenu from "./components/NavbarMenu";
import appInbox from "./screens/App/Inbox";
import appChat from "./screens/App/Chat";
import appCalendar from "./screens/App/Calendar";
import appContact from "./screens/App/Contact";
import appTaskbar from "./screens/App/Taskbar";
import filemanagerdashboard from "./screens/FileManager/Dashboard";
import filedocuments from "./screens/FileManager/Documents";
import filemedia from "./screens/FileManager/Media";
import fileimages from "./screens/FileManager/Images";
import blognewPost from "./screens/Blog/NewPost";
import blogdetails from "./screens/Blog/BlogDetails";
import bloglist from "./screens/Blog/BlogList";
import uitypography from "./screens/UIElements/Typography";
import uitabs from "./screens/UIElements/Tabs";
import uibuttons from "./screens/UIElements/Button";
import bootstrapui from "./screens/UIElements/BootstrapUI";
import uiicons from "./screens/UIElements/Icons";
import uinotifications from "./screens/UIElements/Notifications";
import uicolors from "./screens/UIElements/Colors";
import uilistgroup from "./screens/UIElements/ListGroup";
import uimediaobject from "./screens/UIElements/MediaObject";
import uimodal from "./screens/UIElements/Modals";
import uiprogressbar from "./screens/UIElements/ProgressBar";
import widgetsdata from "./screens/Widgets/Data";
import widgetsweather from "./screens/Widgets/Weather";
import widgetsblog from "./screens/Widgets/Blog";
import widgetsecommers from "./screens/Widgets/ECommers";
import registration from "./screens/Auth/Registration";
import lockscreen from "./screens/Auth/Lockscreen";
import forgotpassword from "./screens/Auth/ForgotPassword";
import page404 from "./screens/Auth/Page404";
import page403 from "./screens/Auth/Page403";
import page500 from "./screens/Auth/Page500";
import page503 from "./screens/Auth/Page503";
import blankpage from "./screens/Pages/BlankPage";
import profilev1page from "./screens/Pages/ProfileV1";
import profilev2page from "./screens/Pages/ProfileV2";
import imagegalleryprofile from "./screens/Pages/ImageGallery";
import timeline from "./screens/Pages/TimeLine";
import pricing from "./screens/Pages/Pricing";
import invoices from "./screens/Pages/Invoices";
import invoicesv2 from "./screens/Pages/InvoicesV2";
import searchresult from "./screens/Pages/SearchResults";
import helperclass from "./screens/Pages/HelperClass";
import teamsboard from "./screens/Pages/TeamsBoard";
import projectslist from "./screens/Pages/ProjectsList";
import maintanance from "./screens/Pages/Maintanance";
import testimonials from "./screens/Pages/Testimonials";
import faqs from "./screens/Pages/Faqs";
import formvalidation from "./screens/Forms/FormValidation";
import basicelements from "./screens/Forms/BasicElements";
import tablenormal from "./screens/Tables/TableNormal";
import echart from "./screens/Charts/Echart";
import leafletmap from "./screens/Maps/GoogleMaps";



//New Code
import { AuthContext } from './shared/context/auth-context';
import { useAuth } from './shared/hooks/auth-hook';
import Dashboard from './screens/Dashbord/Dashbord';
import MainNavigation from './shared/components/Navigation/MainNavigation';
import Demographic from "./screens/Dashbord/Demographic";
import CountryMap from "./screens/Dashbord/CountryMap";
import ExsImport from "./screens/Dashbord/ExsImport";
import RegisterNewUser from "./screens/Dashbord/RegisterNewUser";
import ExsDisplay from "./screens/Dashbord/ExsDisplay";

//New Code

//window.__DEV__ = true;

const App = () => {
  const { token, login, logout, userId } = useAuth();

   let routes;


    


    if (token) {
      routes = (
        <Switch>
          <Route path="/" exact>
          <Dashboard />

        </Route>
          <Route path="/dashboard/dashboard" exact>
           <Dashboard />
          </Route>

          <Route path="/dashboard/demographic" exact>
           <Demographic />
          </Route> 

          <Route path="/dashboard/countrymap" exact>
           <CountryMap />
          </Route> 

          <Route path="/dashboard/exsimport" exact>
           <ExsImport />
          </Route> 

          <Route path="/dashboard/registernewuser" exact>
           <RegisterNewUser />
          </Route> 

          <Route path="/dashboard/exsdisplay" exact>
           <ExsDisplay />
          </Route> 

          <Redirect to="/" />
        </Switch>
      );
    } else {
      routes = (
        <Switch>
          

          <Route path="/login" exact>
            <Login />
          </Route>
         
          <Redirect to="/login" />
        </Switch>
      );
    }




    return (
      
      <AuthContext.Provider
        value={{
          isLoggedIn: !!token,
          token: token,
          userId: userId,
          login: login,
          logout: logout
        }}
      >
        <Router>
        {/* <MainNavigation /> */}
        
       
          
          
         
          <main>{routes}</main>
          {/* <div id="main-content">{routes}</div> */}
        </Router>
      </AuthContext.Provider>
    );
   
  };

export default App;
