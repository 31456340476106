import React, { useState, useContext} from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Logo from "../assets/images/logo.png";
//import { updateEmail, updatePassword,onLoggedin } from "../actions";

import Input from '../shared/components/FormElements/Input';
import ImageUpload from '../shared/components/FormElements/ImageUpload';

import Button from '../shared/components/FormElements/Button';
import { useForm } from '../shared/hooks/form-hook';
import { useHttpClient } from '../shared/hooks/http-hook';
import { AuthContext } from '../shared/context/auth-context';
import {
  VALIDATOR_EMAIL,
  VALIDATOR_MINLENGTH,
  VALIDATOR_REQUIRE
} from '../shared/util/validators';



const Login =() =>
{
  const auth = useContext(AuthContext);
  const [isLoginMode, setIsLoginMode] = useState(true);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  const [formState, inputHandler, setFormData] = useForm(
    {
      email: {
        value: '',
        isValid: false
      },
      password: {
        value: '',
        isValid: false
      }
    },
    false
  );
 


  const switchModeHandler = () => {
    if (!isLoginMode) {
      setFormData(
        {
          ...formState.inputs,
          name: undefined,
        },
        formState.inputs.email.isValid && formState.inputs.password.isValid
      );
    } else {
      setFormData(
        {
          ...formState.inputs,
          name: {
            value: '',
            isValid: false
          },

        },
        false
      );
    }
    setIsLoginMode(prevMode => !prevMode);
  };

  const authSubmitHandler = async event => {
    event.preventDefault();

    if (isLoginMode) {
      try {
        const responseData = await sendRequest(
          'https://server.lampro.co/api/users/login',
          'POST',
          JSON.stringify({
            email: formState.inputs.email.value,
            password: formState.inputs.password.value
          }),
          {
            'Content-Type': 'application/json'
          }
        );
        localStorage.setItem('email', responseData.email);
        localStorage.setItem('name', responseData.name);
        localStorage.setItem('image', responseData.image);
        localStorage.setItem('fee', responseData.fee);
        auth.login(responseData.userId, responseData.token);
      } catch (err) {}
    } else {
      try {
        const formData = new FormData();
        formData.append('email', formState.inputs.email.value);
        formData.append('name', formState.inputs.name.value);
        formData.append('password', formState.inputs.password.value);
        formData.append('image', formState.inputs.image.value);
        formData.append('fee', formState.inputs.fee.value);
        const responseData = await sendRequest(
          'https://server.lampro.co/api/users/signup',
          'POST',
          formData
        );
        localStorage.setItem('email', responseData.email);
        localStorage.setItem('name', responseData.name);
        auth.login(responseData.userId, responseData.token);
        
      } catch (err) {}
    }
  };


    

    return (
  
      <div className="theme-cyan">
   
        {/* <div className="page-loader-wrapper" >
          <div className="loader">
            <div className="m-t-30"><img src={require('../assets/images/logo-icon.svg')} width="48" height="48" alt="Lucid" /></div>
            <p>Please wait...</p>
          </div>
        </div> */}
        <div className="hide-border">
          <div className="vertical-align-wrap">
            <div className="vertical-align-middle auth-main">
              <div className="auth-box">
                <div className="top">
                  <img src={Logo} alt="Lucid" style={{ height: "40px", margin: "10px" }} />
                </div>
                <div className="card">
                  <div className="header">
                    <p className="lead">Login to your account</p>
                  </div>
                  <div className="body" action="index.html">
                    
                    
                      {/* <div className="form-group">
                        <label className="control-label sr-only">Email</label>
                        <input
                          className="form-control"
                          id="email"
                          placeholder="Email"
                          type="email"
                         
                          value="user@thememakker.com"
                          onInput={inputHandler}
                        />
                      </div>
                      <div className="form-group">
                        <label className="control-label sr-only">
                          Password
                        </label>
                        <input
                          className="form-control"
                          id="password"
                          placeholder="Password"
                          type="password"
                         
                          value="secretpassword"
                          onInput={inputHandler}
                          
                        />
                      </div>
                      <div className="form-group clearfix">
                        <label className="fancy-checkbox element-left">
                          <input type="checkbox" />
                          <span>Remember me</span>
                        </label>
                      </div>
                      <a
                        className="btn btn-primary btn-lg btn-block"
                        href="dashboard"
                      >Login</a>  */}


                      <form onSubmit={authSubmitHandler}>
                     
                          {!isLoginMode && (
                            <Input
                              element="input"
                              id="name"
                              className="form-control"
                              placeholder="Username"
                              type="text"
                              label="Your Name"
                              validators={[VALIDATOR_REQUIRE()]}
                              errorText="Please enter a name."
                              onInput={inputHandler}
                            />
                          )}
                          {!isLoginMode && (
                            <ImageUpload
                              center
                              id="image"
                              onInput={inputHandler}
                              errorText="Please provide an image."
                            />
                          )}
                     
                        <div className="input-group mb-3" >
                      <div className="input-group-append">
                      <span className="input-group-text">Username</span>
                        </div>
                        <Input
                          element="input"
                          id="email"
                          type="email"
                          //label="E-Mail"
                          validators={[VALIDATOR_EMAIL()]}
                          errorText="Please enter a valid email address."
                          onInput={inputHandler}
                        />
                        </div>

                        <div className="input-group">
                          <div className="input-group-append
                          ">
                          <span className="input-group-text">Password</span>
                          </div>
                        <Input
                          element="input"
                          id="password"
                          type="password"
                          className="form-control"
                          //label="Password"
                          validators={[VALIDATOR_MINLENGTH(6)]}
                          errorText="Please enter a valid password, at least 6 characters."
                          onInput={inputHandler}
                        />
                        </div>
                       
                        <Button type="submit" className="btn btn-primary btn-lg btn-block" disabled={!formState.isValid}>
                          {isLoginMode ? 'LOGIN' : 'SIGNUP'}
                        </Button>
                        
                      </form>
                     
                      <div className="bottom">
                        {/* <span className="helper-text m-b-10">
                          <i className="fa fa-lock"></i>{" "}
                          <a href={`${process.env.PUBLIC_URL}/forgotpassword`} 
                          >
                            Forgot password?
                          </a>
                        </span> */}
                        {/* <span>
                          Don't have an account?{" "}
                          <a href="registration" >Register</a>
                        </span> */}
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
     
 
    );
  
};

export default Login;

