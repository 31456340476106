import React, { useContext, useState } from "react";
import { Dropdown, Nav, Toast } from "react-bootstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  onPressDashbord,
  onPressDashbordChild,
  onPressThemeColor,
  onPressGeneralSetting,
  onPressNotification,
  onPressEqualizer,
  onPressSideMenuToggle,
  onPressMenuProfileDropdown,
  onPressSideMenuTab,
  tostMessageLoad,
} from "../actions";
import Logo from "../assets/images/logo.png";
import LogoWhite from "../assets/images/logo.png";
import UserImage from "../assets/images/user.png";
import Avatar4 from "../assets/images/xs/avatar4.jpg";
import Avatar5 from "../assets/images/xs/avatar5.jpg";
import Avatar2 from "../assets/images/xs/avatar2.jpg";
import Avatar1 from "../assets/images/xs/avatar1.jpg";
import Avatar3 from "../assets/images/xs/avatar3.jpg";
import { AuthContext } from '../shared/context/auth-context';
import './MobileMenu.css';



const NavbarMenu = () => {
  const auth = useContext(AuthContext);
 
  const email = localStorage.getItem('email');


  const imagePath =  `https://server.lampro.co/${localStorage.getItem( 'image')}`;
  const imageUrl=  `${process.env.PUBLIC_URL}${imagePath.replace(/\\/g, '/')}`;
  //console.log(imageUrl);
  
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

    return (
      <div>
       
        <nav className="navbar navbar-fixed-top">
          <div className="container-fluid">
            <div className="navbar-btn">

                                      <div className="app">
                                <header className="header2">
                                  
                                  
                                  <div className="mobile-menu-toggle" onClick={toggleMobileMenu}>
                                    {/* Button to toggle mobile menu */}
                                    ☰ {/* You can use any icon or text for the toggle button */}
                                  </div>
                                </header>
                                {/* Mobile Menu */}
                                {isMobileMenuOpen && (
                                  <nav className="mobile-menu">
                                    {/* Your mobile menu items */}
                                    <li id="AppContainer" className="">
                                    <Link to ="/dashboard/dashboard" > Dashboard </Link>
                                    </li>
                                    <li id="AppContainer" className="">
                                    <Link  to ="/dashboard/countrymap"> Detailed Analytics </Link>
                                    </li>
                                    <li id="AppContainer" className="">
                                    <Link  to ="/dashboard/exsdisplay"> Accounting </Link>
                                    </li>
                                  </nav>
                                )}
                                {/* Rest of your app content */}
                              </div>




            </div>

            <div className="navbar-brand">
              <a href="dashboard">
                <img
                  src={
                    document.body.classList.contains("full-dark")
                      ? LogoWhite
                      : Logo
                  }
                  alt="Lucid Logo"
                  className="img-responsive logo"
                />
              </a>
            </div>

            <div className="navbar-right">
              
             

              <div id="navbar-menu">
                <ul className="nav navbar-nav">
                  
                  <li>
                    <a
                      href="appcalendar"
                      className="icon-menu d-none d-sm-block d-md-none d-lg-block"
                    >
                      <i className="icon-calendar"></i>
                    </a>
                  </li>
                  
                  
                 
                  <li>
                    <a href="logout" onClick={auth.logout} className="icon-menu">
                      <i className="icon-login"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </nav>

        <div id="left-sidebar" className="sidebar" style={{ zIndex: 9 }}>
          <div className="sidebar-scroll">
            <div className="user-account">
              <img
                //src={UserImage}
                src={imageUrl}
                className="rounded-circle user-photo"
                alt="User Profile"
              />
              <Dropdown>
                <span>Welcome,</span>
                <Dropdown.Toggle
                  variant="none"
                  as="a"
                  id="dropdown-basic"
                  className="user-name"
                >
                  <strong> {localStorage.getItem('name')} </strong>
                </Dropdown.Toggle>

                <Dropdown.Menu className="dropdown-menu-right account">
                  <Dropdown.Item href="profilev2page">
                    <i className="icon-user"></i>My Profile
                  </Dropdown.Item>
                  <Dropdown.Item href="appinbox">
                    {" "}
                    <i className="icon-envelope-open"></i>Messages
                  </Dropdown.Item>
                  <Dropdown.Item>
                    {" "}
                    <i className="icon-settings"></i>Settings
                  </Dropdown.Item>
                  <li className="divider"></li>
                  <Dropdown.Item href="login">
                    {" "}
                    <a onClick={auth.logout}><i className="icon-power"></i>Logout</a>
                  </Dropdown.Item >
                </Dropdown.Menu>
              </Dropdown>
              <hr />
              {/* <ul className="row list-unstyled">
                <li className="col-4">
                  <small>Sales</small>
                  <h6>456</h6>
                </li>
                <li className="col-4">
                  <small>Order</small>
                  <h6>1350</h6>
                </li>
                <li className="col-4">
                  <small>Revenue</small>
                  <h6>$2.13B</h6>
                </li>
              </ul> */}
            </div>
            <ul className="nav nav-tabs">
            
            </ul>
            <div className="tab-content p-l-0 p-r-0">
              <div
                className= "tab-pane active show"
                id="menu"
              >
                <Nav id="left-sidebar-nav" className="sidebar-nav">
                  <ul id="main-menu" className="metismenu">
                    <li className="" id="dashboradContainer">
                      <a
                        href="#"
                        className="has-arrow"
                        onClick={(e) => {
                          e.preventDefault();
                          
                        }}
                      >
                    < Link to ="/dashboard/dashboard" > Dashboard </Link> </a>
                       
                      
                      <ul className="collapse">
                        <li
                          className="active" 
                        >
                          
                        </li>
                        <li
                          className=""
                        >
                          
                        </li>
                        <li className="">
                         
                        </li>
                      </ul>
                    
                    
                    </li>

                   

                    <li id="AppContainer" className="">
                      <a
                        href="/#"
                        className="has-arrow"
                        onClick={(e) => {
                          e.preventDefault();
                          
                        }}
                      >
                      <Link  to ="/dashboard/countrymap"> Detailed Analytics </Link> 
                      </a>
                      <ul className="collapse">
                        <li
                          className="active" 
                          onClick={() => {}}
                        >
                         
                        </li>
                      </ul>
                    </li>

                    <li id="AppContainer" className="">
                      <a
                        href="/#"
                        className="has-arrow"
                        onClick={(e) => {
                          e.preventDefault();
                          
                        }}
                      >
                      <Link  to ="/dashboard/exsdisplay"> Accounting </Link> 
                      </a>
                      <ul className="collapse">
                        <li
                          className="active" 
                          onClick={() => {}}
                        >
                         
                        </li>
                      </ul>
                    </li>
                    {email === 'uppalsaman@gmail.com' && (
                    <li id="AppContainer" className="">
                      <a
                        href="/#"
                        className="has-arrow"
                        onClick={(e) => {
                          e.preventDefault();
                          
                        }}
                      >
                        < Link to ="/dashboard/exsimport"> Excel Import </Link> 
                      </a>
                      <ul className="collapse">
                        <li
                          className="active" 
                          onClick={() => {}}
                        >
                         
                        </li>
                      </ul>
                    </li>
                    )}

                    
                    {email === 'uppalsaman@gmail.com' && (
                    <li id="AppContainer" className="">
                      <a
                        href="/#"
                        className="has-arrow"
                        onClick={(e) => {
                          e.preventDefault();
                          
                        }}
                      >
                      
                      
                       < Link to ="/dashboard/registernewuser"> Register New User </Link> 
                       
                      </a>
                      <ul className="collapse">
                        <li
                          className="active" 
                          onClick={() => {}}
                        >
                         
                        </li>

                      
                      </ul>
                    </li>
                  )}
                  </ul>
                </Nav>
              </div>
              
                
              
            </div>
          </div>
        </div>
      </div>
    );
  
};

export default NavbarMenu