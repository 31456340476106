import React from 'react';
import Slider from 'react-slick';
import '../../assets/assets/scss/StoreSlider.css';



const StoreSlider = ({ data }) => {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 3000,
      arrows: false,
    };
  
    return (
      <Slider {...settings}>
        {data.map(({ store, totalviews, totalearnings, logoUrl }, index) => (
          <div key={index} className="slide">
            <div className="logo-container">
              <img src={logoUrl} alt={store} className="logo" />
              <span className="store-name">{store}</span>
            </div>
            <div className="stats">
              <p>Total Views: {totalviews}</p>
              <p>Total Earnings: {totalearnings}</p>
            </div>
          </div>
        ))}
      </Slider>
    );
  };
  
  export default StoreSlider;