import React, { useState } from 'react';
import axios from 'axios';
import NavbarMenu from "../../components/NavbarMenu";


const ImportExcelComponent = () => {
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleUpload = async () => {
    const formData = new FormData();
    formData.append('excelfile', selectedFile);
    formData.append('filename', "uppalamandeep" );
    try {
      const response = await axios.post('https://server.lampro.co/api/excelupload/excelupload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      console.log(response.data);
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  return (
    <div>
    
    <NavbarMenu />
    

    <div className="container-fluid" id="main-content">
  <h2 className="header">Import Excel</h2>
      <input type="file" onChange={handleFileChange} />
      <button onClick={handleUpload}>Upload</button>
    </div>
    </div>

  );
};

export default ImportExcelComponent;
