import React from 'react';
import '../../assets/assets/scss/DataGrid.css'; // Import CSS for styling


// Custom component to display store logos
const TrackLogo = ({ tracktitle, trackname }) => {
  // You'll need to replace the paths with actual paths to your logo images
  const logoMap = {
    RealTalk: '../../images/logos/realtalk.jpg',
    SheridanBackPlaza2: '../../images/logos/sheridanbackplaza2.jpg',
    Kasoor: '../../images/logos/kasoor.jpg',
    410: '../../images/logos/410-logo.jpg',
    NoRegrets: '../../images/logos/noregrets-logo.jpg',
    

  


    // Add more logos for other stores as needed
  };

  return (
    <div className="logo-container">
      <img src={logoMap[tracktitle]} alt={logoMap[tracktitle]} className="logo" />
      <span className="store-name">{trackname}</span>
    </div>
  );
};

const DataGrid = ({ data }) => {
  return (
    <table className="data-grid">
      <thead>
        <tr>
          <th>Track</th>
          <th>Total Views</th>
          <th>Total Earnings</th>
        </tr>
      </thead>
      <tbody>
        {data.map(({ tracktitle, trackname, totalviews, totalearnings }, index) => (
          <tr key={index} className="data-row">
            <td><TrackLogo tracktitle={tracktitle} trackname={trackname}/></td>
            <td>{totalviews}</td>
            <td>{totalearnings}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default DataGrid;