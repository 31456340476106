import React, { useState, useContext, useEffect  } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import PageHeader from "../../components/PageHeader";
import NavbarMenu from "../../components/NavbarMenu";
import axios from 'axios';

const DataGridPage = () => {

  const [data, setData] = useState([],)
  const [jsonDataS, setjsontDataS] = useState([]);
  const [jsonData2, setjsonData2] = useState([]);
  const [jsonDataT, setjsontDataT] = useState([]);

  const apiLinkTotalMonthlyViews = "https://server.lampro.co/api/excelupload/readfilem/" + localStorage.getItem('name') ;



  useEffect(() => {
    // Fetch data from your Node.js API
    axios.post(apiLinkTotalMonthlyViews, {
          "dategreaterthan": "2023-10-02",
          "datelessthan": "2024-12-03",
          "label": localStorage.getItem('name')
    })
      .then(response => {
        const data = response.data;
        setjsonData2(data);

      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, []); // Empty dependency array means this effect runs only once after initial render




  // Function to get month name from month number
  const getMonthName = (monthNumber) => {
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    return monthNames[monthNumber - 1]; // Months are 0-indexed in JavaScript Date object
  };
  
  // Format data into desired structure
  const formattedData = jsonData2.map(row => {
    const month = getMonthName(row._id);
    return {
        Month: month,
        totalviews: row.totalviews,
        //totalearnings: row.totalearnings
    };
  });
  const formattedData2 = jsonData2.map(row => {
    const month = getMonthName(row._id);
    return {
        month: month,
        //totalviews: row.totalviews,
        totalearnings: row.totalearnings
    };
  });

  const formattedData3 = formattedData2.map((item, index) => ({
    id: index + 1, // Assuming you want ids starting from 1
    ...item // Spread the existing properties of each object
  }));
 // console.log(jsonData2);
  //const totalearningsmonthly = (JSON.stringify(jsonData2.totalearnings))
   //console.log(totalearningsmonthly[0]);
  // console.log(JSON.stringify(jsonData2));
  // console.log(JSON.stringify(jsonData2[0]));
   const totalViews = jsonData2.length > 0 ? jsonData2[0].totalviews : 0;
   const totalEarnings = jsonData2.length > 0 ? jsonData2[0].totalearnings : 0;
  
   const totalEarningsSum = jsonData2.reduce((sum, item) => sum + item.totalearnings, 0).toFixed(2);
   const totalViewsSum= jsonData2.reduce((sum, item) => sum + item.totalviews, 0).toFixed(2);
  // console.log({formattedData2});
  // console.log({formattedData3});
   const fee = localStorage.getItem('fee');
   const userfee = (totalEarningsSum*fee/100).toFixed(2);
  // console.log(userfee);
   const balancepayment = (totalEarningsSum - userfee).toFixed(2);








  const data3 = [
    { id: 1, month: 'December', totalearnings: 104.39 },
    { id: 2, month: 'January', totalearnings: 261.96 },
    
    
  ];

  const data2 = [
    
    
  ];

  const columns = [
    { field: 'month', headerName: 'Month', width: 150 },
    { field: 'totalearnings', headerName: 'Total Earnings', type: 'number', width: 200 },
  ];

  return (

    <div>
    
        {<NavbarMenu />}
        

        <div className="container-fluid" id="main-content">


        <PageHeader
              HeaderText="Accounting"
              Breadcrumb={[{ name: "Accounting" }]}
            />
        
      
        <div className="row clearfix">
            
            <div className="col-lg-6 col-md-12">
              <div className="card">
                <div className="header">
                
                  <h2>Monthly Earnings</h2>
                  
                </div>
                <div className="body">
            
                <div style={{ height: 400, width: '100%', backgroundColor: '#f9f9f9', padding: '20px' }}>
   
   <div style={{ height: 300, width: '100%', backgroundColor: '#fff', borderRadius: '5px', boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)' }}>
     <DataGrid rows={formattedData3}  columns={columns} pageSize={5} />
   </div>
 </div>
                  {/* <ReactEcharts
                    option={topProductOption}
                    opts={{ renderer: "svg" }} // use svg to render the chart.
                  /> */}
                </div>
              </div>
            </div>
            {/* <div className="col-lg-3 col-md-6">
              <ReferralsCard />
            </div> */}
            <div className="col-lg-6 col-md-12">
              <div className="card">
                <div className="header">
                  <h2>Payments</h2>
                  
                </div>
                <div className="body">
            
                <div style={{ height: 400, width: '100%', backgroundColor: '#f9f9f9', padding: '20px' }}>
   
                        <div style={{ height: 300, width: '100%', backgroundColor: '#fff', borderRadius: '5px', boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)' }}>
                          <DataGrid rows={data2}  columns={columns} pageSize={5} />
                        </div>
                </div>
                  {/* <ReactEcharts
                    option={topProductOption}
                    opts={{ renderer: "svg" }} // use svg to render the chart.
                  /> */}
                </div>
                
              </div>
            </div>
          </div>

          

          <div className="col-lg-6 col-md-12">
              <div className="card">
                <div className="header">
                  <h2>Earnings After Fee</h2>
                </div>

                <div className="body">
                <div style={{ height: 400, width: '100%', backgroundColor: '#f9f9f9', padding: '20px' }}>
                <div style={{ height: 300, width: '100%', backgroundColor: '#fff', borderRadius: '5px', boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)' }}>
                          <table >
                          <tr >
                            <td style={{paddingTop:20, paddingLeft:20}} >Total Earnings:</td> <td style={{paddingTop:20, paddingLeft:100}}>:£{totalEarningsSum}</td>
                          </tr>
                          <tr>
                            <td style={{paddingTop:20, paddingLeft:20}}>Total Fee:</td> <td style={{paddingTop:20, paddingLeft:100}}>:£{userfee}</td>
                          </tr>
                          <tr>
                            <td style={{paddingTop:20, paddingLeft:20}}>Total Payments:</td> <td style={{paddingTop:20, paddingLeft:100}}>:£0</td>
                          </tr>
                          <tr>
                            <td style={{paddingTop:20, paddingLeft:20, fontSize:16, fontWeight:500}}>Balance Payments:</td> <td style={{paddingTop:20, paddingLeft:100, fontSize:16, fontWeight:500}}>£{balancepayment}</td>
                          </tr>
                          </table>
                          
                </div>

                </div>

                </div>
              </div>
          </div>
    

    </div>
    </div>
  );
};

export default DataGridPage;
