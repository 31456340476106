import React, { useState, useContext } from 'react';
import Logo from "../../assets/images/logo.png";
import Card from '../../shared/components/UIElements/Card';
import Input from '../../shared/components/FormElements/Input';
import Button from '../../shared/components/FormElements/Button';
import ErrorModal from '../../shared/components/UIElements/ErrorModal';
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner';
import ImageUpload from '../../shared/components/FormElements/ImageUpload';
import {
  VALIDATOR_EMAIL,
  VALIDATOR_MINLENGTH,
  VALIDATOR_REQUIRE
} from '../../shared/util/validators';
import { useForm } from '../../shared/hooks/form-hook';
import { useHttpClient } from '../../shared/hooks/http-hook';
import { AuthContext } from '../../shared/context/auth-context';
import NavbarMenu from "../../components/NavbarMenu";


const RegisterNewUser = () => {
  const auth = useContext(AuthContext);
  const [isLoginMode, setIsLoginMode] = useState(true);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  const [formState, inputHandler, setFormData] = useForm(
    {
      email: {
        value: '',
        isValid: false
      },
      password: {
        value: '',
        isValid: false
      }
    },
    false
  );

  

  const userCreateHandler = async event => {
    event.preventDefault();

    if (isLoginMode) {
      try {
       
        const formData = new FormData();
        formData.append('email', formState.inputs.email.value);
        formData.append('name', formState.inputs.name.value);
        formData.append('password', formState.inputs.password.value);
        formData.append('image', formState.inputs.image.value);
        formData.append('fee', formState.inputs.fee.value);

        const responseData = await sendRequest(
          'https://server.lampro.co/api/users/signup',
          'POST',
          formData
        );

        //auth.login(responseData.userId, responseData.token);
        console.log("User Created");
      } catch (err) {}
    }
  };
  
  return (
    <React.Fragment>
            <NavbarMenu />
            <div className="theme-cyan">
            <div className="container-fluid" id="main-content">
                
               
               
                <div >
                <div className="top">
                  <img src={Logo} alt="Lucid" style={{ height: "40px", margin: "10px" }} />
                </div>


                <div >
                  <div className="header">
                    <p className="">Create New User</p>
                  </div>




                    <div action="index.html">
                        <ErrorModal error={error} onClear={clearError} />
                            
                               
                               
                                <form onSubmit={userCreateHandler}>
                                <div className="input-group mb-3" > 
                                    <div className="input-group-append">
                                      <span className="input-group-text" style={{width:150}}>Username</span>
                                    </div>
                                    <Input
                                    element="input"
                                    id="name"
                                    className="form-control"
                                    placeholder="username"
                                    type="text"
                                    //label ="Name"
                                    validators={[VALIDATOR_REQUIRE()]}
                                    errorText="Please enter a name."
                                    onInput={inputHandler}
                                    />
                                
                                </div>
                                
                                <div className="input-group mb-3" > 
                                    <div className="input-group-append">
                                      <span className="input-group-text" style={{width:150}}>Email Address</span>
                                    </div>
                                <Input
                                    element="input"
                                    id="email"
                                    type="email"
                                    placeholder="email"
                                    validators={[VALIDATOR_EMAIL()]}
                                    errorText="Please enter a valid email address."
                                    onInput={inputHandler}
                                />
                                </div>
                                
                                <div className="input-group mb-3" > 
                                    <div className="input-group-append">
                                      <span className="input-group-text" style={{width:150}}>Password </span>
                                    </div>
                                <Input
                                    element="input"
                                    id="password"
                                    type="password"
                                    placeholder="*****"
                                    validators={[VALIDATOR_MINLENGTH(6)]}
                                    errorText="Please enter a valid password, at least 6 characters."
                                    onInput={inputHandler}
                                />
                                </div>

                                <div className="input-group mb-3" > 
                                    <div className="input-group-append">
                                      <span className="input-group-text" style={{width:150}}>User Fee</span>
                                </div>
                                    <Input
                                    element="input"
                                    id="fee"
                                    type="text"
                                    placeholder="0"
                                    validators={[VALIDATOR_REQUIRE()]}
                                    errorText="Please enter a fee."
                                    onInput={inputHandler}
                                    />
                                
                                </div>
                                <div className="input-group mb-3" style={{border:0, background:false}} > 
                                    <div className="input-group-append" style={{border:0, background:false}}>
                                      
                                    </div>
                                    <ImageUpload
                                    center
                                    id="image"
                                    onInput={inputHandler}
                                    errorText="Please provide an image."
                                    />
                                </div>
                               
                                <div className="input-group mb-3" style={{marginTop:280, marginLeft:30}} > 
                                <Button type="submit"  disabled={!formState.isValid}  >
                                    {isLoginMode ? 'LOGIN' : 'SIGNUP'}
                                </Button>
                                </div>
                                </form>
       
                            
                    </div>
                </div>
                </div>
                </div>
        </div>

    </React.Fragment>
  );
};

export default RegisterNewUser;
